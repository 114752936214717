import './SiteHeader.css';
import React, { Component } from 'react';
import Theme from '../theme';
import spaxlogo from '../assets/sixpetal_logo.png';
import skelogo from '../assets/ske_logo.png';
import gatelogo from '../assets/gate_logo.png';

import { Link, Typography, Box, Button } from '@mui/material';
import { GitHub } from '@mui/icons-material';

class SiteHeader extends Component {
    state = {}
    render() {
        return (<>
            <div className='blur1'></div>
            <div className='blur2'></div>
            <header className='header' id="header">
                <Typography variant='h2' style={{ fontWeight: 600 }}>
                    <Box display='inline' style={{ color: Theme.color.q1 }}>Redmorus</Box> Policy Generator
                </Typography>
                <Typography className='pd10' variant='h5'>Generate a privacy policy and terms &amp; conditions for your services</Typography>
                <Button className='pd10' variant="outlined" color="secondary"><GitHub /> &nbsp; Fork me</Button>
            </header>
            <div className='header'>
                <Link className='startbtn' href="#start" color="white" underline="none">Start</Link>
                <div className="ourclient">
                    <Button className='pd10' variant="none"><img src={spaxlogo} height={32} alt='' /></Button>
                    <Button className='pd10' variant="none"><img src={skelogo} height={32} alt='' /></Button>
                    <Button className='pd10' variant="none"><img src={gatelogo} height={32} alt='' /></Button>
                </div>
            </div>
        </>);
    }
}

export default SiteHeader;