import './UserForm.css';
import './radioselect.css';
import React, { Component } from "react";

import FormControl from '@mui/material/FormControl';
import { Grid, Container, Typography, Link, Button, InputLabel, TextField, MenuItem, Select, Checkbox, FormControlLabel, CircularProgress, Backdrop, SvgIcon } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { NavigateNext, NavigateBefore, CheckCircleOutline } from '@mui/icons-material';
import theme from '../theme';


class UserForm extends Component {
    paddings = { paddingLeft: 20, paddingTop: 10 }
    toppadding = { paddingTop: 30 }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            show_title: true,
            frm_type: '',
            frm_organization: '',
            frm_name: '',
            frm_mail: '',
            frm_contact: '',
            frm_address: '',
            frm_template: '',
            frm_apptype: '',
            frm_appname: '',
            frm_orgnickname: '',
            frm_tagline: '',
            frm_website: '',
            frm_logo: '',
            frm_poster: '',
            frm_title: '',
            frm_iseu: true,
            frm_isenc: true,
            frm_isloc: false,
            frm_issensor: false,
            frm_iscontact: false,
            frm_issms: false,
            frm_personal: ['Name', 'Email', 'Address', 'Gender', 'Age'],
            frm_third: [],
        };
        this.template = [];
        this.personalfield = ['Name', 'Email', 'Phone number', 'Address', 'Gender', 'Age or Year of birth', 'Date of birth', 'Race', 'Ethnicity', 'Sexual orientation', 'Religion', 'Political View'];
        this.thirdperty = ['Google Play Services', 'AdMob', 'Google Analytics', 'Google Analytics for Firebase', 'Firebase Crashlytics', 'Facebook', 'Fabric', 'Clicky', 'Flurry Analytics', 'Fathom Analytics', 'Unity', 'GameAnalytics', 'One Signal', 'Expo', 'Sentry', 'AppLovin', 'StartApp', 'AdColony'];
        this.validate = false;
        this.gotoPage = this.gotoPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.templatebase = "https://api.sixpetal.com/policymaker/";
        this.apibase = "https://api.sixpetal.com/policymaker/";

    }

    gotoPage(section) {
        this.validate = false;
        this.setState({ page: section });
    }

    validatePageOne() {
        this.validate = true;
        if (this.state.frm_type.trim() === '' || this.lenThree(this.state.frm_organization) ||
            this.isMail(this.state.frm_mail) || this.isPhone(this.state.frm_contact))
            return false;
        return true;
    }
    validatePageThree() {
        this.validate = true;
        if (this.isEmpty(this.state.frm_apptype) || this.isEmpty(this.state.frm_appname) ||
            this.isEmpty(this.state.frm_website) || this.isURL(this.state.frm_website) ||
            this.isURL(this.state.frm_logo))
            return false;
        return true;
    }

    handleSubmit(e) {
        this.setState({ loading: true });
        e.preventDefault();
        fetch(this.apibase + "new_doc.php", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                type: this.state.frm_type,
                organization: this.state.frm_organization,
                name: this.state.frm_name,
                mail: this.state.frm_mail,
                contact: this.state.frm_contact,
                address: this.state.frm_address,
                template: this.state.frm_template,
                apptype: this.state.frm_apptype,
                appname: this.state.frm_appname,
                orgnickname: this.state.frm_orgnickname,
                tagline: this.state.frm_tagline,
                website: this.state.frm_website,
                logo: this.state.frm_logo,
                poster: this.state.frm_poster,
                title: this.state.frm_title,
                iseu: this.state.frm_iseu,
                isenc: this.state.frm_isenc,
                isloc: this.state.frm_isloc,
                issensor: this.state.frm_issensor,
                iscontact: this.state.frm_iscontact,
                issms: this.state.frm_issms,
                personal: this.state.frm_personal,
                third: this.state.frm_third,
            }).toString()
        }).then((res) => {
            return res.json();
        }).then((data) => {
            console.log(data);
            let obj = sessionStorage.getItem("policy");
            if (obj === null || obj.length === 0) {
                sessionStorage.setItem("policy", JSON.stringify(data));
            } else {
                sessionStorage.setItem("policy", obj + ',' + JSON.stringify(data));
            }
            this.setState({ loading: false, page: -1 });
        }).catch((err) => {
            console.error(err);
            this.setState({ loading: false });
        });
    }
    isEmpty(s) {
        return s.trim() === '';
    }
    lenThree(s) {
        return s.trim().length < 3;
    }
    isMail(m) {
        return m.match('^[A-z][A-z0-9_\\.-]*@[A-z0-9\\-_\\.]+\\.[A-z]{2,4}$') === null;
    }
    isPhone(ph) {
        return ph.match('^(\\+?\\d{6,15})?$') === null;
    }
    isURL(s) {
        return s.match('^((https?:\\/\\/)?[A-z0-9\\-]+\\.[A-z0-9\\-\\.]+(\\/.*)?)?$') === null;
    }
    addThird(val) {
        this.setState({ frm_third: [...this.state.frm_third, val] })
    }
    removeThird(val) {
        this.setState({
            frm_third: this.state.frm_third.filter(function (v) {
                return v !== val;
            })
        });
    }
    addPersonal(val) {
        this.setState({ frm_personal: [...this.state.frm_personal, val] })
    }
    removePersonal(val) {
        this.setState({
            frm_personal: this.state.frm_personal.filter(function (v) {
                return v !== val;
            })
        });
    }
    addPersonalFiled(val) {
        if (val === '') return;
        this.personalfield = [...this.personalfield, val];
        this.setState({ frm_personal: [...this.state.frm_personal, val] })
    }

    render() {
        return (
            <Container maxWidth="md" id="start">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Typography variant='h4' component='p' style={this.toppadding}>
                    We need some information
                </Typography>
                <form onSubmit={this.handleSubmit}>
                    <div className={'page ' + (this.state.page === 1 ? "" : "hidepane")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}><strong>General information</strong></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>What you are looking for</InputLabel>
                                    <Select label="What you are looking for" onChange={(e) => {
                                        this.setState({
                                            frm_type: e.target.value,
                                            frm_title: e.target.value,
                                        });
                                    }} error={this.isEmpty(this.state.frm_type) && this.validate}>
                                        <MenuItem value={'Privacy policy'}>Privacy policy</MenuItem>
                                        <MenuItem value={'Terms and conditions'}>Terms and Conditions</MenuItem>
                                        <MenuItem value={'License and registration'}>License and Registration</MenuItem>
                                        <MenuItem value={'Terms of service'}>Terms of Service</MenuItem>
                                        <MenuItem value={'License agreement'}>License agreement</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField id="organization" label="Developer or organization name" variant="outlined" onChange={(e) => {
                                        this.setState({ frm_organization: e.target.value });
                                    }} error={this.lenThree(this.state.frm_organization) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField id="name" label="Your name (Optional)" variant="outlined" onChange={(e) => {
                                        this.setState({ frm_name: e.target.value });
                                    }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField id="mail" label="Email address" variant="outlined" type="mail" onChange={(e) => {
                                        this.setState({ frm_mail: e.target.value });
                                    }} error={this.isMail(this.state.frm_mail) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField id="contact" label="Contact number (Optional)" variant="outlined" onChange={(e) => {
                                        this.setState({ frm_contact: e.target.value });
                                    }} error={this.isPhone(this.state.frm_contact) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="address" label="Organization address (Optional)" variant="outlined" helperText="One line address" onChange={(e) => {
                                        this.setState({ frm_address: e.target.value });
                                    }} />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item lg={4} xs={12}>
                                <Button id='nextpolicy' onClick={(e) => {
                                    if (this.validatePageOne()) {
                                        this.setState({ loading: true });
                                        fetch(this.apibase + 'get_autofill.php', {
                                            method: "POST",
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/x-www-form-urlencoded',
                                            },
                                            body: new URLSearchParams({
                                                organization: this.state.frm_organization,
                                                name: this.state.frm_name,
                                                mail: this.state.frm_mail,
                                                contact: this.state.frm_contact,
                                                address: this.state.frm_address,
                                            }).toString()
                                        }).then((res) => {
                                            return res.json();
                                        }).then((data) => {
                                            console.log();
                                            if (data.length !== 0) {
                                                data = data[0];
                                                this.setState({
                                                    frm_orgnickname: data.othername,
                                                    frm_tagline: data.tagline,
                                                    frm_website: data.website,
                                                    frm_logo: data.logo,
                                                })
                                            }
                                            return fetch(this.apibase + 'get_template.php?t=' + this.state.frm_type);
                                        }).then((res) => {
                                            return res.json();
                                        }).then((data) => {
                                            this.template = data;
                                            this.gotoPage(2);
                                        }).catch((err) => {
                                            console.error(err);
                                        }).finally(() => {
                                            this.setState({ loading: false });
                                        });
                                    }
                                    else {
                                        this.setState({});
                                    }
                                }}>
                                    Next <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === 2 ? "" : "hidepane")}>
                        <Grid container spacing={2} className="plans">
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}>
                                    <strong>Select the template of your policy</strong>
                                </Typography>
                            </Grid>
                            {
                                this.template.map(item => {
                                    let img = this.templatebase + item.path + "/poster.png"
                                    return (<Grid item xs={12} lg={4} sm={6} key={"item" + item.id.toString()}>
                                        <FormControl fullWidth>
                                            <label className="plan">
                                                <input type="radio" name="template" value={item.id} onClick={(e) => {
                                                    this.setState({
                                                        frm_template: e.target.value,
                                                        frm_poster: item.path + '/poster.png'
                                                    });
                                                }} />
                                                <div className="plan-content">
                                                    <img loading="lazy" src={img} alt="" />
                                                    <div>{item.name}</div>
                                                </div>
                                            </label>
                                        </FormControl>
                                    </Grid>)
                                })
                            }
                        </Grid>
                        {this.template.length === 0 ?
                            <Typography variant='p' color='red' align='center'>
                                No template found . . .
                            </Typography> : ''}
                        {(!this.state.frm_template && this.validate) ?
                            <Typography variant='p' color='red' align='center'>
                                <small>Select the template of your policy</small>
                            </Typography> : ""}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} xs={6} >
                                <Button id='nextpolicy' onClick={() => this.gotoPage(1)}>
                                    <NavigateBefore /> Back
                                </Button>
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => {
                                    this.validate = true;
                                    if (!this.isEmpty(this.state.frm_template))
                                        this.gotoPage(3);
                                    this.setState({});
                                }} >
                                    Next <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === 3 ? "" : "hidepane")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}>
                                    <strong>Product and policy information</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Application Type</InputLabel>
                                    <Select label="Application Type" onChange={(e) => {
                                        this.setState({ frm_apptype: e.target.value });
                                    }} name="apptype" error={this.isEmpty(this.state.frm_apptype) && this.validate}>
                                        <MenuItem value={'Free'}>Free</MenuItem>
                                        <MenuItem value={'Open Source'}>Open Source</MenuItem>
                                        <MenuItem value={'Freemium'}>Freemium</MenuItem>
                                        <MenuItem value={'Ad Supported'}>Ad Supported</MenuItem>
                                        <MenuItem value={'Commercial'}>Commercial</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField label="Product name" onChange={(e) => {
                                        this.setState({ frm_appname: e.target.value });
                                    }} id="productname" name="productname" variant="outlined" error={this.isEmpty(this.state.frm_appname) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField label="Orgnization other name (Optional)" onChange={(e) => {
                                        this.setState({ frm_orgnickname: e.target.value });
                                    }} id="othername" name="othername" variant="outlined" value={this.state.frm_orgnickname} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField label="Orgnization slougan (Optional)" onChange={(e) => {
                                        this.setState({ frm_tagline: e.target.value });
                                    }} id="tagline" name="tagline" variant="outlined" value={this.state.frm_tagline} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <TextField label="Website" onChange={(e) => {
                                        this.setState({ frm_website: e.target.value });
                                    }} id="website" name="website" variant="outlined" value={this.state.frm_website} error={(this.isEmpty(this.state.frm_website) || this.isURL(this.state.frm_website)) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <TextField label="Logo URL (Optional)" onChange={(e) => {
                                        this.setState({ frm_logo: e.target.value });
                                    }} id="logo" name="logo" variant="outlined" value={this.state.frm_logo} error={this.isURL(this.state.frm_logo) && this.validate} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <TextField label="Poster URL (Optional)" onChange={(e) => {
                                        this.setState({ frm_poster: e.target.value });
                                    }} id="poster" name="poster" variant="outlined" value={this.state.frm_poster} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Show title in document" onChange={(e) => {
                                        if (e.target.checked)
                                            this.setState({ show_title: e.target.checked, frm_title: this.state.frm_type, });
                                        else
                                            this.setState({ show_title: e.target.checked, frm_title: '' });
                                    }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <TextField id="title" label="Title of document" onChange={(e) => {
                                        this.setState({ frm_title: e.target.value });
                                    }} value={this.state.frm_title} variant="outlined" name="title" disabled={!this.state.show_title} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(2)}>
                                    <NavigateBefore /> Back
                                </Button>
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => {
                                    if (this.validatePageThree())
                                        this.gotoPage(4);
                                    this.setState({});
                                }}>
                                    Next <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === 4 ? "" : "hidepane")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}>
                                    <strong>Device compliance and access</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {
                                        this.setState({ frm_iseu: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="iseu" label="GDPR compliance in EU region." />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {
                                        this.setState({ frm_isenc: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="encrypt" label="Using encryption over communication." />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                        this.setState({ frm_isloc: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="islocation" label="Using location based service." />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                        this.setState({ frm_issensor: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="sensor" label="Accessing other mobile sensors." />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                        this.setState({ frm_iscontact: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="iscontact" label="Accessing contact book." />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                        this.setState({ frm_issms: e.target.checked });
                                    }} checkedIcon={<Check />} icon={<Close />} />} name="issms" label="Accessing SMS and SMS based service." />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(3)}>
                                    <NavigateBefore /> Back
                                </Button>
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(5)} >
                                    Next <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === 5 ? "" : "hidepane")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}>
                                    <strong>Personal data requirements</strong>
                                </Typography>
                            </Grid>
                            {
                                this.personalfield.map((item) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={4} key={"m2" + item}>
                                            <FormControl fullWidth>
                                                <FormControlLabel control={<Checkbox checked={this.state.frm_personal.indexOf(item) > -1} onChange={(e) => {
                                                    if (e.target.checked)
                                                        this.addPersonal(e.target.name);
                                                    else
                                                        this.removePersonal(e.target.name);
                                                }} checkedIcon={<Check />} icon={<Close />} />} name={item} label={item} />
                                            </FormControl>
                                        </Grid>
                                    );
                                })

                            }
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="personaldata" label="Other personal data (Comma Separated)" variant="outlined" onChange={(e) => {
                                        if (e.target.value.endsWith(',')) {
                                            let str = e.target.value.slice(0, -1);
                                            e.target.value = '';
                                            this.addPersonalFiled(str);
                                        }
                                    }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(4)}>
                                    <NavigateBefore /> Back
                                </Button>
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(6)} >
                                    Next <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === 6 ? "" : "hidepane")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='p' style={this.paddings}>
                                    <strong>Third-party software and API used</strong>
                                </Typography>
                            </Grid>
                            {
                                this.thirdperty.map((item) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={4} key={'d3' + item}>
                                            <FormControl fullWidth>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    if (e.target.checked)
                                                        this.addThird(e.target.name);
                                                    else
                                                        this.removeThird(e.target.name);
                                                }} checkedIcon={<Check />} icon={<Close />} />} name={item} label={item} />
                                            </FormControl>
                                        </Grid>
                                    );
                                })
                            }

                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={() => this.gotoPage(5)}>
                                    <NavigateBefore /> Back
                                </Button>
                            </Grid>
                            <Grid item lg={4} xs={6}>
                                <Button id='nextpolicy' onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={'page ' + (this.state.page === -1 ? "" : "hidepane")}>
                        <Grid container spacing={2} alignItems='center' style={{ height: '100%' }}>
                            <Grid item xs={12} align='center'>
                                <SvgIcon style={{ transform: 'scale(4)', paddingBottom: 10 }} color='success'> <CheckCircleOutline /></SvgIcon>
                                <Typography variant='h4' style={this.paddings} color={theme.color.p2}>
                                    Successfully Generate
                                </Typography>
                                <Link href='/view' underline="none">View all</Link>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Container>
        );
    }
}

export default UserForm;