class Theme {
    color = {
        p1: "#2C6975",
        p2: "#68B2A0",
        p3: "#CDE0C0",
        p4: "#E0ECDE",
        q1: "#9c27b0",
        q2: "#BA86F6",
        q3: "#CFB7FF",
        q4: "#A8C0F9",
        white: "#FFFFFF",
        black: "#030334",
        gray: "#474141",
    }
}

export default new Theme();