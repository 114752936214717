import './BannerExplain.css';
import React,{ Component } from "react";
import { Typography } from '@mui/material';
import bannericon from '../assets/policy.svg'

class BannerExplain extends Component {
    state = {}
    render() {
        return (<>
            <div className='banner'>
                <img src={bannericon} alt='' />
                <div>
                    <Typography variant='p' component='p'>Why it is important?</Typography>
                    <Typography variant='h3' component='p'>Business with transparency</Typography>
                    <Typography variant='h6' component='p'>It explains the details about the company, views about the data obtained from visitors and establishment procedures.</Typography>
                </div>
            </div>
        </>);
    }
}

export default BannerExplain;