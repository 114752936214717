import { Delete, OpenInBrowser } from "@mui/icons-material";
import { Avatar, Backdrop, Card, CircularProgress, Divider, Grid, Link, SvgIcon, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { Component } from "react";
import theme from "../theme";

class PolicyList extends Component {
    templatebase = "https://api.sixpetal.com/policymaker/";
    state = {}
    render() {
        let data = [];
        if (sessionStorage.getItem("policy") !== null)
            data = JSON.parse('[' + sessionStorage.getItem("policy") + ']');
        return (
            <Container maxWidth="md" id="start">
                {
                    this.state.loading ? <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.loading}
                        onClick={() => { this.setState({ loading: !this.state.loading }) }} >
                        <CircularProgress color="inherit" />
                    </Backdrop> : ""
                }
                <Typography variant='h4' component='p' style={{ padding: "10px 0" }}>
                    My document
                </Typography>

                <Grid container spacing={2}>
                    {
                        data.map((item) => {
                            return (
                                <Grid item xs={12} sm={6} key={'cd23' + item.id}>
                                    <Card>
                                        <Box sx={{ p: 2, display: 'flex' }}>
                                            <Avatar variant="rounded" src={this.templatebase + item.path + '/poster.png'} sx={{ bgcolor: theme.color.q2, height: 64, width: 128, objectFit: 'cover' }}>{item.type[0]}</Avatar>
                                            <Stack style={{ padding: '0 20px' }}>
                                                <Link href={'get/' + item.id} underline="none" color='unset'>
                                                    <Typography fontWeight={700}>{item.name}</Typography>
                                                    <Typography variant="body" color="text.secondary">
                                                        <OpenInBrowser />
                                                    </Typography>
                                                </Link>
                                            </Stack>
                                        </Box>
                                        <Divider />
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: theme.color.p4, cursor: 'pointer' }}>
                                            <span><small>Set to auto delete</small></span>
                                            <SvgIcon color='success'><Delete /></SvgIcon>
                                        </Stack>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        );
    }
}

export default PolicyList;