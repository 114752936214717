import './App.css';
import React, { Component } from 'react';

import SiteHeader from './component/SiteHeader';
import BannerExplain from './component/BannerExplain';
import theme from './theme';
import UserForm from './component/UserForm';
import { Typography } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import PolicyList from './component/PolicyList';


class App extends Component {
    render() {
        return (
            <div style={{ color: theme.color.black }}>
                <SiteHeader />
                <BannerExplain />
                <Routes>
                    <Route path='/' element={<UserForm />} />
                    <Route path='/view' element={<PolicyList />} />
                </Routes>
                <div style={{
                    height: "200px",
                    background: theme.color.gray,
                    color: theme.color.white,
                    marginTop: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <div>
                        <Typography variant='h5'>
                            &copy; Redmorus, 2022
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='p'>
                            <small style={{ letterSpacing: '2px' }}>Transforming technology</small>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
