import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const GetDocument = () => {
    const params = useParams()
    let apibase = "https://api.sixpetal.com/policymaker/";
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Policy</title>
                <meta name="description" content="Policy" />
                <link rel="canonical" href={apibase + 'get.php?q=' + params.q.toString()} />
            </Helmet>
            <iframe src={apibase + 'get.php?q=' + params.q.toString()} style={{ width: '100%', height: '100vh' }} title='Document'></iframe>
        </>
    );
};

export default GetDocument;